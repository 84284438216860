<template>
	<w-layout v-if="categoryId" column fill-height>
		<ListOfCatalogServices :catalog-service-id="catalogServiceId" :category-id="categoryId"></ListOfCatalogServices>
	</w-layout>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'CategoryDetails',
	components: {
		ListOfCatalogServices: () => ({
			component: import('@/components/Offers/ListOfCatalogServices')
		})
	},
	mixins: [OffersModuleGuard],
	props: {
		catalogServiceId: {
			type: Number,
			required: false,
			default: undefined
		},
		categoryId: {
			type: Number,
			required: false,
			default: undefined
		}
	},
	watch: {
		categoryId: {
			handler: function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.service.onCategoryIdUpdated(newVal)
				} else if (!newVal) {
					this.service.selectDefaultCategory()
				}
			},
			immediate: true
		}
	}
}
</script>
